@use "../../../styles/variables" as var;

.accordion-header-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  cursor: pointer;

  @media (max-width: var.$mobile-max) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    align-items: center;
    color: #fff;
    transition: 0.15s linear;

    &.is-wrapper-hovered {
      background: #232323;
      color: var.$color-main;
    }
  }
}

.accordion-items-outer-wrapper {
  background: #000;
  position: absolute;
  transition: 0.1s;
  max-height: 0;
  opacity: 0;

  @media (max-width: var.$mobile-max) {
    position: static;
    transition: max-height 0s, opacity 0.1s;
  }

  .accordion-items-wrapper {
    transition: 0.1s;
    margin: 0;
    padding: 0 16px;

    > .accordion-item {
      position: relative;
      width: fit-content;
      transition: 0.3s ease-out;
      opacity: 0;
      line-height: 0;

      @media (max-width: var.$mobile-max) {
        transition: line-height 0s, opacity 0.1s;
      }

      > a {
        font-size: 0;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        transition: 0.1s linear;
        height: 1px;
        background: var.$color-main;
        width: 0;
      }
    }
  }

  &.is-open {
    max-height: 100vh;
    opacity: 1;

    .accordion-items-wrapper {
      margin-top: 30px;
      padding: 8px 16px 0px 16px;

      > .accordion-item {
        opacity: 1;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        margin-bottom: 16px;

        > a {
          font-size: 16px;
        }

        &:hover {
          color: var.$color-main;

          &:after {
            width: 100%;
          }
        }
      }

      @media (max-width: var.$mobile-max) {
        margin-top: 8px;
      }
    }
  }
}
