@use "../../../../styles/variables" as var;

.button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 30px;

    > svg {
      fill: var.$color-main;
    }
  }
}
