@use "../../../../styles/variables" as var;

.button {
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(var.$color-main, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  &:after {
    position: absolute;
    width: 2px;
    opacity: 0;
    background: #fff;
    height: 100%;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.15s linear;
  }

  &:hover:not(:disabled) {
    &:after {
      width: 100%;
      opacity: 0.4;
    }
  }

  &:disabled {
    background: rgba(151, 151, 151, 0.6);
    border: 1px solid #979797;
    color: rgba(255, 255, 255, 0.38);
  }

  @media (max-width: var.$mobile-max) {
    height: 48px;
    width: 100%;
  }
}
