@use "../styles/variables" as var;

@font-face {
  font-family: "RictyDiminished";
  src: url("../public/font/RictyDiminished-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "RictyDiminished";
  src: url("../public/font/RictyDiminished-Bold.ttf");
  font-weight: bold;
}
:root {
  --primary-color: #00acb3;
  --secondary-color: #e2511e;
  --background-color: #000000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-black min-h-full;
  }
}

html {
  scroll-behavior: smooth;
}

.sp {
  display: none;
  @media (max-width: var.$mobile-max) {
    display: inline-block;
  }
}

* {
  letter-spacing: 0.06em;
}

.text-outline {
  text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    -1px -1px 0 #000;
}

.swiper .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-pagination-bullets {
  bottom: -40px !important;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border-radius: 0;
  border: 1px solid #fff;
  margin: 0 18px !important;
  box-sizing: border-box;
}

.swiper-pagination-bullet-active {
  background-color: var.$color-main;
  border: transparent;
}
