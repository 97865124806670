@use "../../../styles/variables" as var;

.loadingPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background: #000;
  background-image: url("/images/loading/circle.png");
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: var.$mobile-max) {
    background-image: url("/images/loading/sp-circle.png");
    background-size: cover;
  }
}

.logoWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -240px);

  @media (max-width: var.$mobile-max) {
    transform: translate(-50%, -140px);
  }
}

.loadingCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 80px);

  @media (max-width: var.$mobile-max) {
    transform: translate(-50%, 30px);
  }
}

$animation-delay-unit: 0.15s;
$animation-duration: $animation-delay-unit * 8;

.rect {
  position: absolute;
  width: 7px;
  height: 21px;
  border: 1px solid var.$color-main;

  @media (max-width: var.$mobile-max) {
    width: 4px;
    height: 10.9px;
  }

  animation-name: rect-flash;
  animation-duration: $animation-duration;
  animation-iteration-count: infinite;

  @mixin rect-position($radius, $position-45deg) {
    &:nth-child(1) {
      transform: translate(0, -$radius);
      animation-delay: 0s;
    }
    &:nth-child(2) {
      transform: translate($position-45deg, -$position-45deg) rotate(45deg);
      animation-delay: $animation-delay-unit;
    }
    &:nth-child(3) {
      transform: translate($radius, 0) rotate(90deg);
      animation-delay: $animation-delay-unit;
    }
    &:nth-child(4) {
      transform: translate($position-45deg, $position-45deg) rotate(135deg);
      animation-delay: $animation-delay-unit * 3;
    }
    &:nth-child(5) {
      transform: translate(0, $radius) rotate(180deg);
      animation-delay: $animation-delay-unit * 4;
    }
    &:nth-child(6) {
      transform: translate(-$position-45deg, $position-45deg) rotate(225deg);
      animation-delay: $animation-delay-unit * 5;
    }
    &:nth-child(7) {
      transform: translate(-$radius, 0) rotate(270deg);
      animation-delay: $animation-delay-unit * 6;
    }
    &:nth-child(8) {
      transform: translate(-$position-45deg, -$position-45deg) rotate(315deg);
      animation-delay: $animation-delay-unit * 7;
    }
  }

  @include rect-position(52px, 36.8px);

  @media (max-width: var.$mobile-max) {
    @include rect-position(27px, 19.1px);
  }
}

@keyframes rect-flash {
  0% {
    background-color: var.$color-main;
  }

  15% {
    background-color: transparent;
  }
}

.loadingTextWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 180px);

  @media (max-width: var.$mobile-max) {
    transform: translate(-50%, 100px);
  }

  animation-name: text-fade;
  animation-duration: $animation-duration;
  animation-iteration-count: infinite;
}

@keyframes text-fade {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
