@use "../../../styles/variables" as var;

.footer {
  background-color: #000;
  height: 196px;
  padding: 40px 48px 40px 48px;
  display: flex;
  justify-content: space-between;

  @media (max-width: var.$mobile-max) {
    height: 278px;
    flex-direction: column;
    justify-content: center;
  }
}

.sub-kv-wrapper {
  width: 320px;
  height: 64px;
  position: relative;

  @media (max-width: var.$mobile-max) {
    width: 200px;
    height: 40px;
    margin: 0 auto 0 auto;
  }
}

.copy {
  font-size: 16px;
  line-height: 16px;
  color: #ccc;
  margin-top: 31px;

  @media (max-width: var.$mobile-max) {
    font-size: 14px;
    text-align: center;
  }
}

.sns-link-items-wrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  .sns-link-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover path {
      fill: var.$color-main;
    }
  }

  @media (max-width: var.$mobile-max) {
    justify-content: center;
    margin-top: 40px;
  }
}

.text-link-items-wrapper {
  display: flex;
  gap: 10px 20px;
  padding-left: 10px;

  .text-link-wrapper {
    color: #fff;
    transition: 0.3s ease-out;
    position: relative;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    box-sizing: border-box;
    white-space: nowrap;

    > .underline {
      position: absolute;
      content: "";
      transition: 0.1s linear;
      bottom: -2px;
      left: 0;
      height: 1px;
      background-color: var.$color-main;
      width: 0;
    }

    &:hover {
      color: var.$color-main;

      > .underline {
        width: 100%;
      }
    }

    &:first-child {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        width: 1px;
        height: 12px;
        background-color: #fff;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      width: 1px;
      height: 12px;
      background-color: #fff;
    }
  }

  @media (max-width: var.$mobile-max) {
    justify-content: center;
    flex-wrap: wrap;
  }
}
