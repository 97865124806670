@use "../../../styles/variables" as var;

.header {
  background-color: rgb(0 0 0);
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  position: sticky;
  display: flex;
  align-items: center;

  height: 80px;
  @media (max-width: var.$mobile-max) {
    height: 40px;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 48px;

  @media (max-width: var.$mobile-max) {
    padding: 0 8px 0 16px;
  }
}

.nav-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.nav-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  @media (max-width: var.$mobile-max) {
    margin: 20px 0;
  }
}

.nav-list-1st-child {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  transition: 0.3s ease-out;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    transition: 0.1s linear;
    height: 1px;
    background: var.$color-main;
    width: 0;
  }

  .nav-anchor {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;

    &.active {
      color: var.$color-main;
    }
  }

  &.is-active {
    color: var.$color-main;
  }

  &:hover:not(.is-accordion),
  &.is-open {
    &:after {
      width: 100%;
    }

    color: var.$color-main;
  }
}

.nav-button-item {
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #fff;
  border-bottom: 1px solid transparent;
  transition: 0.2s;
  margin-bottom: 16px;

  &:hover {
    color: var.$color-main;
    > a {
      border-bottom: 1px solid var.$color-main;
    }
  }
}

.sp-nav-link {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  align-items: center;
  color: #fff;
  transition: 0.1s linear;
  font-weight: bold;

  &:hover {
    background-color: #232323;
    color: var.$color-main;

    svg {
      fill: var.$color-main;
    }
  }
}

.nav-lang {
  margin-left: 81px;
  cursor: pointer;

  @media (max-width: 1150px) {
    margin-left: 32px;
  }
}

.lang-button {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  color: #fff;
  padding: 4px 0;
  font-weight: bold;
  position: relative;
  transition: 0.2s;

  &:after {
    content: "";
    transition: 0.1s linear;
    position: absolute;
    bottom: 1px;
    left: 0;
    height: 1px;
    background-color: var.$color-main;
    width: 0;
  }

  > .svg_global_network_icon {
    height: 22px;
    width: 22px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 9px;
    fill: #ffffff;
    transition: 0.2s;
  }

  &.is-open {
    color: var.$color-main;

    &:after {
      width: 100%;
    }

    > .svg_global_network_icon {
      fill: var.$color-main;
    }
  }

  @media (max-width: var.$mobile-max) {
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    > .svg_global_network_icon {
      margin-right: 0;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
      border-radius: 30px;

      &:after {
        height: 0;
      }
    }
  }
}

.pc-lang-accordion-contents-wrapper {
  position: absolute;
  background: #000;
  top: 30px;
  left: -16px;

  .pc-lang-accordion-contents-ul {
    margin-top: 0px;
    padding: 0 16px;
    transition: 0.1s linear;

    .nav-lang-item {
      position: relative;
      font-size: 0;
      line-height: 0;
      margin-bottom: 0;
      font-weight: bold;
      color: #fff;
      transition: 0.1s linear;
      transition: color 0.3s ease-out;
      padding-right: 32px;

      &:after {
        position: absolute;
        content: "";
        transition: 0.1s linear;
        bottom: -2px;
        left: 0;
        height: 1px;
        background-color: var.$color-main;
        width: 0;
      }

      &:hover {
        color: var.$color-main;

        &:after {
          width: 100%;
        }
      }
    }
  }

  &.is-open .pc-lang-accordion-contents-ul {
    margin-top: 30px;
    padding: 8px 16px 0;

    .nav-lang-item {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  }
}

.buy-nav {
  margin-left: 68px;

  .buy-text {
    margin-right: 8px;
    color: #fff;
    opacity: 1;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  }

  @media (max-width: var.$mobile-max) {
    margin: 24px 16px 0 16px;
  }
}

.hamburger {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s linear;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 30px;

    > svg {
      fill: var.$color-main;
    }
  }
}

.menu-modal {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10;
  transition: all 0.3s linear;
  visibility: hidden;

  &.modal-show {
    top: 0;
    visibility: visible;
  }

  .menu-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    height: 62px;

    .close-button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 30px;

        > svg {
          fill: var.$color-main;
        }
      }
    }
  }
}
